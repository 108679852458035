import { io } from 'socket.io-client';
const API_URL = process.env.VUE_APP_API_URL;

let socket = null;

export function createSocket() {
    if (!socket) {
        if (localStorage.getItem('token'))
        {
            socket = io(API_URL, {
                auth: {
                    token: localStorage.getItem('token')
                },
                forceNew: false,
                secure: true,
            });
        }
    }
    return socket;
}

export function getSocket() {
    if (!socket) {
        socket = createSocket();
    }

    return socket;
}

export function disconnectSocket() {
    if (socket) {
        socket.disconnect();
        socket = null;
    }
}
