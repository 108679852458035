<template>
  <v-card>
    <v-layout>

      <v-container class="MainCard">
        <v-main>
          <HeaderBar/>

          <NavBar/>
          <div class="router-container">
            <router-view></router-view>
          </div>
        </v-main>
      </v-container>
    </v-layout>
  </v-card>
</template>

<script>
import NavBar from '@/components/nav/NavBar.vue';
import HeaderBar from '@/components/nav/HeaderBar.vue';
import utils from "@/modules/utils";
import dbUtils from "@/modules/dbUtils";
import {nextTick} from "vue";
import { getSocket, disconnectSocket } from '@/modules/socketService';

export default {
  data: () => ({
    drawer: false,
    group: null,
    socket: null,
    userCount: 0,
  }),

  components: {
    NavBar, HeaderBar
  },

  watch: {
    group() {
      this.drawer = false
    },
  },

  async mounted() {
    await nextTick();

    this.socket = getSocket();

    const sToken = localStorage.getItem('token');
    if (!sToken || sToken === '' || sToken === null) {
      this.$nextTick(() => {
        this.$router.push('/login');
      });
    }
    else {
      let userRole = (utils.decodeToken(sToken)).role;
      let adminRole = (await dbUtils.getConfigs('ROLE_FROM_SOGEDIS')).split(',').map(extension => parseInt(extension.trim().replace(/'/g, ''), 10));

      if(adminRole.includes(userRole)) {
        this.startInactivityTimer();
      }
    }

  },

  beforeUnmount() {
    if (this.socket) {
      disconnectSocket();
    }
  },

  methods: {
    startInactivityTimer() {
      window.addEventListener("mousemove", this.resetTimer);
      window.addEventListener("keypress", this.resetTimer);

      const inactivityDuration = 3 * 60 * 60 * 1000; // 60 * 60 * 1000 = 1 heure en millisecondes

      this.inactivityTimer = setTimeout(() => {
        this.logout();
      }, inactivityDuration);
    },

    resetTimer() {
      clearTimeout(this.inactivityTimer);
      this.startInactivityTimer();
    },

    logout() {
      localStorage.removeItem('token');
      localStorage.removeItem('vuex');
      localStorage.removeItem('_secure__ls__metadata');
      document.location.href = "/login";
    }
  }
}
</script>


<style scoped>
.MainCard {
  /*background-image: url('https://images.unsplash.com/photo-1659878997289-b9ab5a0663b6?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=80') !important;*/
  background-image: url('@/assets/wallpaper.jpg');
  background-position: center;
  background-size: cover;
  min-height: 100vh;
  min-width: 100vw;
}

/*.fadePage-enter-active, .fadePage-leave-active {*/
/*  transition: opacity 0.3s;*/
/*}*/

/*.fadePage-enter, .fadePage-leave-to {*/
/*  opacity: 0;*/
/*}*/
</style>
